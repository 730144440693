if (jQuery('body').is('#index')) {
    jQuery(document).ready(function($){
        setupImage($('.js-sticky-menu .logo>img'))
        $('.js-sticky-menu').on('sticky-start', function(){
            if (jQuery(window).width() < 767) {
                setAlternateImage($('.js-sticky-menu .logo>img'))
            } else {
                setOrigImage(jQuery('.js-sticky-menu .logo>img'));
            }
        });
        $('.js-sticky-menu').on('sticky-end', function(){
            if (jQuery(window).width() < 767) {
                setOrigImage(jQuery('.js-sticky-menu .logo>img'));
            } else {
                setAlternateImage($('.js-sticky-menu .logo>img'))
            }
        })
    });
    jQuery(window).resize(function(){
		if (jQuery(window).width() < 767 
			|| (jQuery(window).width() > 768 && jQuery('#sticky-wrapper').hasClass('is-sticky'))
		) {
            setOrigImage(jQuery('.js-sticky-menu .logo>img'));
        } else {
			setAlternateImage(jQuery('.js-sticky-menu .logo>img'));
		}
    });
    function setupImage( $img ){
        $img.attr('data-orig-image', $img.attr('src'));
        if (jQuery(window).width() > 768) {
            setAlternateImage($img);
        }
    }
    function setOrigImage( $img ) {
        $img.attr('src', $img.attr('data-orig-image'))
    }
    function setAlternateImage($img ) {
        $img.attr('src', $img.attr('data-alt-image'));
    }
}