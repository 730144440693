jQuery(document).ready(function($){
    $('.main-header .search-widget').each(function(){
        $searchwidget = $(this);
        $searchwidget.find('form').submit(function(){
            if ($searchwidget.hasClass('is-open')) {
                return true;
            } else {
                $searchwidget.addClass('is-open');
                return false;
            }
        });
        $(this).click(function(e){
            e.stopPropagation();
        })
    });
    $('body').click(function(){
        $('.main-header .search-widget').removeClass('is-open');
    })
});